.field-set{
  select{
    padding: 1.5rem;
    color: #898989;
    text-transform: uppercase;
    font-size: inherit;
    option{
      text-transform: initial;
      padding: 1rem;
      line-height: 4rem;
    }
  }

  @media only screen and (max-width: 87.5em) {
    select {
      padding: 1.07rem 1.5rem;
    }
  }
}
