.event{
  &__slider{
    width: 100%;
    max-width: 90rem;
    position: relative;
    .slick-arrow{
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      z-index: 9;
      width: 3.5rem;
      height: 3.5rem;
      font-size: 0;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      &:hover{
        box-shadow: 3px 3px 5px rgba(0,0,0,0.4);
      }
      &.slick-prev{
        left: -5rem;
        background-image: url("../../assets/svg-icon/left-arrow-black.svg");
      }
      &.slick-next{
        right: -5rem;
        background-image: url("../../assets/svg-icon/right-arrow-black.svg");
      }
    }
  }
  &__slide{
    padding: .5rem;
    &-content{
      padding: 1rem;
      margin: auto;
      border: 2px solid #000;
      width: 26.8rem;
      height: 20.2rem;
      .img{
        height: 18.2rem;
      }
    }
    &:focus{
      outline: none;
    }
    &.active{
      .event__slide-content{
        border: 2px solid #3aaf50;
      }
    }
  }
  @media only screen and (max-width: 87.5em) {
    &__slider{
      max-width: 560px;
      .slick-arrow{
        width: 3rem;
        height: 3rem;
        &.slick-prev,
        &.slick-next{
          background-size: 35%;
        }
        &.slick-prev{
          left: -3.5rem;
        }
        &.slick-next{
          right: -3.5rem;
        }
      }
    }
    &__slide{
      padding: .5rem;
      &-content{
        padding: 5px;
        border: 1px solid #000;
        width: 175px;
        height: 135px;
        .img{
          background-size: cover;
          background-position: center center;
          height: 125px;
        }
      }
      &.active{
        .event__slide-content{
          border: 1px solid #3aaf50;
        }
      }
    }
  }
}
