.m-a{
    margin-left: auto ;
    margin-right: auto;
}

.m{
    margin: $space;
}

.m-x{
    margin-left: $space;
    margin-right: $space;
}

.m-y{
    margin-top: $space;
    margin-bottom: $space;
}

.m-l{
    margin-left: $space;
}

.m-r{
    margin-right: $space;
}

.m-t{
    margin-top: $space;
}

.m-b{
    margin-bottom: $space;
}

.m-xs{
    margin: $space-xs;
}

.m-x-xs{
    margin-left: $space-xs;
    margin-right: $space-xs;
}

.m-y-xs{
    margin-top: $space-xs;
    margin-bottom: $space-xs;
}

.m-l-xs{
    margin-left: $space-xs;
}

.m-r-xs{
    margin-right: $space-xs;
}

.m-t-xs{
    margin-top: $space-xs;
}

.m-b-xs{
    margin-bottom: $space-xs !important;
}

.m-sm{
    margin: $space-sm;
}

.m-x-sm{
    margin-left: $space-sm;
    margin-right: $space-sm;
}

.m-y-sm{
    margin-top: $space-sm;
    margin-bottom: $space-sm;
}

.m-l-sm{
    margin-left: $space-sm;
}

.m-r-sm{
    margin-right: $space-sm;
}

.m-t-sm{
    margin-top: $space-sm;
}

.m-b-sm{
    margin-bottom: $space-sm;
}

.m-md{
    margin: $space-md;
}

.m-x-md{
    margin-left: $space-md;
    margin-right: $space-md;
}

.m-y-md{
    margin-top: $space-md;
    margin-bottom: $space-md;
}

.m-l-md{
    margin-left: $space-md;
}

.m-r-md{
    margin-right: $space-md;
}

.m-t-md{
    margin-top: $space-md;
}

.m-b-md{
    margin-bottom: $space-md;
}

.m-lg{
    margin: $space-lg;
}

.m-x-lg{
    margin-left: $space-lg;
    margin-right: $space-lg;
}

.m-y-lg{
    margin-top: $space-lg;
    margin-bottom: $space-lg;
}

.m-l-lg{
    margin-left: $space-lg;
}

.m-r-lg{
    margin-right: $space-lg;
}

.m-t-lg{
    margin-top: $space-lg;
}

.m-b-lg{
    margin-bottom: $space-lg;
}

.m-xlg{
    margin: $space-xlg;
}

.m-x-xlg{
    margin-left: $space-xlg;
    margin-right: $space-xlg;
}

.m-y-xlg{
    margin-top: $space-xlg;
    margin-bottom: $space-xlg;
}

.m-l-xlg{
    margin-left: $space-xlg;
}

.m-r-xlg{
    margin-right: $space-xlg;
}

.m-t-xlg{
    margin-top: $space-xlg;
}

.m-b-xlg{
    margin-bottom: $space-xlg;
}