.form{
  &.login,
  &.forgot-password{
    padding: 3rem 0;
    width: 85%;
    max-width: 500px;
  }
  &__heading{
    font-size: 3.8rem;
    font-weight: 500;
  }
  &__sub-heading{
    font-size: 2rem;
  }
  .forgot-link{
    position: absolute;
    top:1rem;
    right: 0;
  }
  //1400
  @media only screen and (max-width: 87.5em) {
    &.login,
    &.forgot-password{
      max-width: 440px;
    }
    &__heading{
      font-size: 3.5rem;
    }
    &__sub-heading{
      font-size: 1.6rem;
      font-weight: 400;
    }
    .forgot-link{
      top:.5rem;
    }
  }
}
