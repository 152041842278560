.rich-text-editor{
  &__container{
    border:1px solid #dadbe3;
    border-radius: 3px;
  }
  &__toolbar{
    background-color: #f3f3f6;
    margin: 0;
    height: 45px;
    .rdw-colorpicker-wrapper{
      margin: 0;
    }
    .options-box{
      padding-right: 10px;
      &.color-picker,
      &-icon{
        border: none;
        background-color: transparent;
        margin: 0;
        padding: 0;
        width: 18px;
        height: 18px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        img{
          width: 12px;
        }
        &.bold,
        &.italic,
        &.underline{
          img{
            width: 10px;
          }
        }
        &.bullets,
        &.number-list
        {
          img{
            width: 14px;
          }
        }
        &.link{
          img{
            width: 15px;
          }
        }
      }
      &.color-picker{
        margin: 0;
        padding: 0;
        img{
          width: 15px;
        }
      }
    }
  }
  &__text-area{
    height: 200px;
    overflow: auto;
    padding: 1.5rem;

    .public-DraftStyleDefault-ltr{
      margin: .4rem 0;
    }
    .DraftEditor-root,
    .public-DraftEditor-content{
      height: auto;
    }
  }
  .short-code{
    font-size: 14px;
    padding: .5rem 0;
    a, span{
      font-size: inherit;
    }
  }
  // 1400
  @media only screen and (max-width: 87.5em) {
    &__toolbar{
      padding-top: 0;
      height: 40px;
      .options-box{
        margin-bottom: 0;
      }
    }
    &__text-area{
      height: 180px;
    }
    .short-code{
      font-size: 12px;
      font-weight: 500;
    }
  }
}
