$green : #3aaf50;

.event-card{
  padding: 1.5rem 2rem 0 0;
  width: 33%;
  &__box{
    margin: 2.25rem 2.25rem  0 0;
    height: 100%;
    padding-bottom: 2rem;
  }
  &__frame{
    padding: 5rem 2rem 0 2rem;
    height: 100%;
  }
  &__img{
    max-width: 375px;
    height: 230px;
  }
  &__btn{
    position: absolute;
    top: -1.25rem;
    right: -1.25rem;
  }
  &__title{
    margin-top: 3.5rem;
    font-size: 1.8rem;

  }
  &__link{
    display: inline-flex;
    margin-top: 1.5rem;
    color: #898989;
    font-size: 1.6rem;
    & &-icon{
      width: 4rem;
      margin: 0 1rem 0 0;
    }
  }
  &.active{
    .event-card__frame{
      border: 2px solid $green;
    }
  }

  // 1400
  @media only screen and (max-width: 87.5em) {
    &__frame{
      padding: 3rem 1.5rem 0 1.5rem;
    }
    &__img{
      height: 200px;
    }
    &__box{
      margin: 1.5rem 1.5rem  0 0;
    }
    //&__btn{
    //  position: absolute;
      //top: -1.5rem;
      //right: -1.5rem;
    //}
    &__title{
      margin-top: 3rem;
      font-size: 1.7rem;
    }
  }

  @media only screen and (max-width: 56.25em) {
    &__img{
      height: 155px;
    }
  }

  @media only screen and (max-width: 56.25em) {
    width:  100%;
    max-width: 280px;
    padding-right: 0;
    &__img{
      height: 175px;
    }
  }
}
