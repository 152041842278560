.text-area{
  border: none;
  margin: 1.5rem 0;
  textarea{
    width: 100%;
    padding: 1.5rem;
    border: 1px solid #dadbe3;
    border-radius: 3px;
  }
  .limit-section{
    font-size: 14px;
    padding: .5rem 1.5rem;
    a, span{
      font-size: inherit;
    }
  }
  @media only screen and (max-width: 87.5em) {
    textarea{
      height: 150px !important;
    }
    .limit-section{
      font-size: 12px;
     font-weight: 500;
    }
  }
}
