$primary : #3aaf50;

.card{
  color: #898989;
  width: 30rem;
  min-height: 30rem;
  height: 100%;
  border: none;
  border-top: 5px solid $primary;
  border-radius:0;
  &__value{
    color: #000;
    font-size: 6rem;
    font-weight: 600;
    margin: 1.3rem 0 .5rem 0;
  }
  &__title,
  &__text{
    color:inherit;
    font-size: 2.4rem;
    font-weight: 500;
  }
  //1400
  @media only screen and (max-width: 87.5em) {
    width: 25rem;
    min-height: 25rem;
    &__value{
      font-size: 5rem;
    }
    &__text{
      font-size: 2rem;
    }
  }
}
