$green : #3aaf50;
$red : #d0021b;
$gray : #cdcdcd;

.tab-button{
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 2rem;
  color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2.4rem;
  border-bottom: 5px solid transparent;
  .icon{
    width: 4rem;
    img{
      max-width: 100%;
    }
    .active-icon{
      display: none;
    }
  }
  &.active{
    color: $green;
    border-color: $green;
    .icon{
      .default-icon{
        display: none;
      }
      .active-icon{
        display: inline-block;
      }
    }
  }
  @media only screen and (max-width: 87.5em) {
    padding: 1.4rem;
    .icon{
      width: 3.5rem;
    }
  }
}
