$primary : #3aaf50;
.field-set{
    .switch__label{
            display: block;
            margin-bottom: .8rem;
            font-weight: 600;
            color: #898989;

    }
}
.switch {
  display: inline-flex;
  align-items: center;
  z-index: 1;
  &__input {
    display: none;
  }
  &__box {
    width: 60px;
    height: 30px;
    display: inline-block;
    position: relative;
    background-color: #dcdada;
    border-radius: 50px;
    transition: all .5s;
    .dot {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   background-color: #fff;
   position: absolute;
   top: 0;
   left: 0;
   transition: all .5s;
   border:1px solid #2d2d2d2d;
    }
  }
  &.white{
    .switch__box{
      border: 2px solid #fff;
    }
  }
 
  .switch__input:checked + .switch__box .dot {
    transform: translateX(30px);
    border-color: #dcdada;
    
  }

  .switch__input:checked + .switch__box {
    background-color: $primary;
  }
}
