.rb-image{
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    img{
      max-width: 100%;
      max-height: 100%;
    }
      &.loading{
          background-image: url('../../assets/images/img-placeholder.jpg');
      }
  }