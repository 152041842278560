.createEvent-dialog{
  .rb-dialog__body{
    padding: 0;
  }
  .rb-dialog__content{
    max-width: 90rem;
    position: relative;
  }
  .dialog-modal__container {
    overflow: initial;
    padding-right: 0 !important;
  }

  .form-container{
    max-height: calc(85vh - 200px);
    overflow: hidden;
    overflow-y: auto;
    &-text__error{
      color: red;
      font-size: 1.3rem;
      min-height:43px;
    }
    .form-fields{
      width: 72%;
      h3{
        color: #000;
      }
    }

  }

  .form-buttons{
    padding: 20px 12px;
  }

  .create-event{
    &-menu{
      height: 127px;
    }
    &__tab{
      position: relative;
      &-icon{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: #898989;
        z-index: 5;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 45%;
      }
      &-title{
        font-size: 1.2rem;
      }
      &-1, &-2, &-3{
        &:before{
          content: '';
          width: 100%;
          height: 15px;
          background-color: #ced0da;
          position: absolute;
          top:2.5rem;
          left: 0;
        }
      }

      &-1, &-2{
        &:after{
          width: 15px;
          height: 15px;
          position: absolute;
          top:2.5rem;
          right: -7.5px;
          background-color: #3aaf50;
          z-index: 5;
          border-radius: 50%;
        }
      }
      &-1, &-3{
        &:before{
          width: calc(100% - 4rem);
        }
      }

      &-1{
        .create-event__tab-icon{
          background-image: url("../../../assets/svg-icon/clipboard-white.svg");
        }
        &:before{
          left: initial;
          right: 0;
        }
      }
      &-2{
        .create-event__tab-icon{
          background-image: url("../../../assets/svg-icon/email-white.svg");
        }
      }

      &-3{
        .create-event__tab-icon{
          background-image: url("../../../assets/svg-icon/sms-white.svg");
        }
      }

      &.active{
        .create-event__tab-icon{
          background-color: #223150;
        }
        &:before{
          background-color: #3aaf50;
        }
        &:after{
          content: '';
        }
      }
    }
  }

  .image-preview{
    padding: 0 20px;
    img{
      width: 100%;
      margin: auto;
    }
  }

  // 1400
  @media only screen and (max-width: 87.5em) {
    .rb-dialog__content{
      max-width: 80rem;
      position: relative;
    }

    .create-event{
      &__tab{
        &-icon{
          width: 5rem;
          height: 5rem;
        }
        &-1, &-2, &-3{
          &:before{
            height: 1rem;
            top: 2rem;
          }
        }

        &-1, &-2{
          &:after{
            width: 1rem;
            height: 1rem;
            top:2rem;
            right: -.5rem;

          }
        }
      }
    }

    .create-event-menu{
      .create-event-menu-container{
        max-width: 430px;
      }
    }

    .form-container{
      .form-fields {
        max-width: 435px;
        h3{
          font-size: 2.4rem;
        }
      }
    }
  }
}
