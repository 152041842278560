.details{
  .event-img{
    width: 60%;
    max-width: 590px;
    height: 440px;
    margin-right: 2.2rem;
    //img{
    //  width: 100%;
    //}
  }
}