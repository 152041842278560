.event-template{
  .heading{
    font-size: 3.5rem;
  }
  &__error{
    color: red;
    font-size: 1.3rem;
    margin-top: 2rem;
  }
  @media only screen and (max-width: 87.5em) {
    .heading{
      font-size: 2.8rem;
    }
  }
}
