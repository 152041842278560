.event-media{
  .media-box{
    width: 33%;
    padding: 2rem 2rem 0 0;
    &__container{
      position: relative;
    }
    .media-image{
      width: 100%;
      max-width: 350px;
      height: 260px;
      background-color: #e7e7e7;
      border: 1px solid #e0e0e0;
      .gif{
        height: inherit;
      }
      &.box-size{
        *{
          box-sizing: initial;
        }
      }
    }
    .check-event{
      position: absolute;
      top:1.5rem;
      left:1.5rem;
    }
  }
  &-section{
    max-height: 750px;
  }
  @media only screen and (max-width: 56.25em) {
    .media-box {
      width: 100%;
      max-width: 300px;
    }
  }
}

// .rh5v-DefaultPlayer_controls{
//   box-sizing: initial !important;
// }
