$primary : #3aaf50;

.event-details{
  &__header{
   padding: 2rem 1.4rem;
    &-back{
      min-height: 42px;
      button{
        width: 2.4rem;
        display: inline-block;
        img{
          width: 100%;
        }
      }

    }
    &-name{
      min-height: 42px;
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;
    }
    &-back,
    &-button{
      width: 18rem;
    }
  }
  &__nav{
    background-color: #f3f3f3;
    ul{
      min-height: 60px;
      li{
        a{
          font-size: 2.4rem;
          font-weight: bold;
          &.active{
            color: $primary;
          }
        }
      }
    }
  }

  //1400
  @media only screen and (max-width: 87.5em) {
    &__nav{
      ul{
        min-height: 50px;
      }
    }
  }
  //600
  @media only screen and (max-width: 56.25em) {
    &__header{
        flex-wrap: wrap;
      &-name{
       width: 100%;
        order: -1;
        margin-bottom: 10px;
      }
      &-back,
      &-button{
        width: 50%;
      }
    }
    &__nav{
      background-color: #f3f3f3;
      ul{
        padding: 0;
        min-height: 70px;
        flex-wrap: wrap;
        li{
          width: 50%;
          a{
            font-size: 16px;
            padding-left: 10px ;
          }
        }
      }
    }
  }

}
