.current-event{
  width: 100%;
  max-width: 75rem;
  margin: 0 20px;
  &__label{
    font-size: 4rem;
    margin-bottom: .5rem;
  }
  &__title{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }
  &__box{
    border: 1px solid #000;
    padding: 2rem 7rem;
  }
  &__img{
    max-width: 500px;
    width:100%;
    height: 350px;
  }
  .primary-btn.medium{
    font-size: 15px;
  }
  //1400
  @media only screen and (max-width: 87.5em) {
    &__img{
      max-width: 438px;
      height: 325px;
    }
    &__box{
      max-width: 570px;
      margin: auto;
      padding: 1rem 7rem;
    }
  }

  @media only screen and (max-width: 56.25em) {
    &__label{
      font-size: 2rem;
    }
    &__title{
      font-size: 1.8rem;
    }
    &__box{
      padding: 1rem;
      margin: 1rem;
    }
  }
}

@media only screen and (max-width: 56.25em) {
  &__img{
    height: 230px;
  }
  .events-buttons {
    .primary-btn {
      margin: 0 !important;
      width: 178px;
      &.file-btn {
        margin-bottom: 5px !important;
      }
    }
  }
}
