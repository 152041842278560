.f-14{
  font-size: 1.4rem;
}
.f-18{
  font-size: 1.8rem;
  line-height: 2.8rem;
}
.f-22{
  font-size: 2.2rem;
  line-height: 3rem;
}

.f-24{
  font-size: 2.4rem;
  line-height: 3.5rem;
}

.primary-text{
  color: $primary;
}


.secondary-text{
   color: $secondary;
 }

.gray-text{
  color: $gray-text;
}


.hover-primary-text{
  &:hover{
    color: $primary;
  }
}


.white-text{
  color: $white;
}
.black-text{
  color: $black;
}


.danger-text{
  color: $danger;
}
.light-gray-text{
  color: #898989;
}

.center{
  text-align: center;
}

.right{
  text-align: right !important;
}

.uppercase{
  text-transform: uppercase;
}

.capitalize{
  text-transform: capitalize;
}

.semi-bold{
  font-weight: 700;
}

.bold{
  font-weight: bold;
}

.bolder{
  font-weight: bolder;
}

.a{
  text-decoration: none;
  border-bottom: 1px solid transparent;
  &, &:active, &:focus{
    color: #64a0e6;
  }
  &:hover{
    border-color: #64a0e6;
  }
  &.border-none{
    &:hover{
      border-color: transparent;
    }
  }
}

.relative{
  position: relative;
}

.rotate-90{
  transform: rotate(90deg);
}

.block{
  display: block;
}

.inline-block{
  display: inline-block;
}

.l-h-3{
  line-height: 3rem;
}

.p-18{
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.article-p-18{
  p{
    @extend .p-18
  }
}

.article-l-h-3{
  p{
    @extend .l-h-3
  }
}

.hidden{
  display: none;
}

.h-100{
  height: 100vh;
}

.bg-white{
  background-color: $white;
}

.fixed{
  position: fixed !important;
}

.pointer{
  cursor: pointer;
}

.disabled:hover{
  cursor:not-allowed !important;
}
