
@font-face {
  font-family: "Klavika Bold";
  src: url("./app/assets/fonts/klavika/Klavika-Bold.otf") format("opentype");
  src: url("./app/assets/fonts/klavika/Klavika-Light.otf") format("opentype");
  src: url("./app/assets/fonts/klavika/Klavika-Medium.otf") format("opentype");
  src: url("./app/assets/fonts/klavika/Klavika-Regular.otf") format("opentype");
}

$secondary-font-family: 'Klavika Bold';

.primary-font{
  font-family: 'Montserrat', sans-serif !important;
}

.secondary-font{
  font-family: $secondary-font-family !important;
}

.Toastify__toast-body{
 @extend .primary-font;
}

.cover-img{
  background-image: url('./app/assets/images/img-placeholder.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    max-height: 100%;
  }
}