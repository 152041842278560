.loading{
  .dot{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 0 3px;
    &-1{
      background-color: #3aaf50;
      animation: loading .8s .1s linear infinite;
    }
    &-2{
      background-color: #223150;
      animation: loading .8s .2s linear infinite;
    }
    &-3{
      background-color: #25631d;
      animation: loading .8s .3s linear infinite;
    }
    &-4{
      background-color: #071534;
      animation: loading .8s .4s linear infinite;
    }
  }
}

@keyframes loading {
  0% {transform: translate(0,0);}
  50% {transform: translate(0,15px);}
  100% {transform: translate(0,0);}
}


