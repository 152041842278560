.rb-dialog {
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  &__content {
    width: 90%;
    max-width: 75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__body {
    width: 100%;
    min-height: 200px;
    border-radius: 5px;
    padding: 4rem;
    background-color: #fff;
    transition: all .8s;

  }
  &.active {
    opacity: 1;
    visibility: visible;
    .rb-dialog__body {
      transform: translateY(0);
    }
  }
  &.dialog-modal{
    .rb-dialog__body{
      padding-right: 0;
    }
    .dialog-modal__container{
      max-height: 85vh;
    }
    .close-dialog{
      position: absolute;
      top:10px;
      right: 10px;
      img{
        width: 2.5rem;
      }
    }
  }
  &.scrolling{
    .rb-dialog__body{
      padding: 45px 0 20px 20px;
    }
    .dialog-modal__container{
      padding-right: 20px;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: transparent;
        transition: background-color .3s;
      }
      &:hover{
        &::-webkit-scrollbar-thumb {
          background-color: rgba(#3aaf50, 0.8);
        }
      }
    }
  }
}
.rb-dialog__body-content{
  &-icon{
    width: 100%;
    max-width: 110px;
    max-height: 110px;
    margin-bottom: 20px;
    min-height: 100px;
  }
  &-title{
    font-size: 2.5rem;
  }
}



// 1400
@media only screen and (max-width: 87.5em) {
  .rb-dialog {
    &.dialog-modal{
      .close-dialog{
        img{
          width: 2rem;
        }
      }
    }
  }
}
